<template>
  <div>
    <!-- 个人页面 类似裂变页 背景图 + 顶部区域的个人头像 昵称 + 中间位置的echarts图 + 右下区域的二维码 + 底部提示长摁保存图片 -->
    <div id="posterHtml">
      <!-- 背景图 -->
      <img id="bg-img" src="../assets/bg2.jpg" alt="" />

      <!-- 顶部个人信息 -->
      <div class="profile">
        <img class="user-avatar" :src="user.avatar" alt="">
        <div class="profile-right">
          <span class="user-name">{{ user.username }}</span>
          <span class="user-tip">已加入测评, 邀请你一起</span>
        </div>
        <!-- <img class="user-avatar" src="https://thirdwx.qlogo.cn/mmopen/vi_32/oYFPa0PNp43jfoHiaMnicn3HWg32Yv3oTLag4NwvKZjZR7dK3EYiaROEmkFGT6JxQcNp47RjliajVgBRwa9LQnWyoA/132" alt="">
        <div class="profile-right">
          <span class="user-name">{{ user.username }}</span>
          <span class="user-tip">已加入测评, 邀请你一起</span>
        </div> -->
      </div>

      <!-- 个人能力分析echarts图 -->
      <div class="chart-container">
        <div id="myChart" ></div>
      </div>
      
      <!-- 底部二维码 -->
      <div class="qrcode">
        <vue-qr class="qrcode-img" :text="user.front_qrcode" :size="100" :margin="2"></vue-qr>
        <!-- <vue-qr class="qrcode-img" text="https://wechat.ceba.ceshiren.com/qrcode?url=wechat&state=om73e1RkYMpHdrDOsoNu17V7O-yA" :size="100" :margin="2"></vue-qr> -->
      </div>
    </div>

    <!-- 底部提示文字 -->
    <div class="tips">
      <div class="triangle"></div>
      <span>长按保存图片,发送给朋友</span>
    </div>

    <!-- 菜单 -->
    <div class="menu">
      <div id= "evaluation" class="left" @click="goHome">返回首页</div>
    </div>

  </div>
</template>

<script>
import html2canvas from "html2canvas";
import VueQr from "vue-qr";
import axios from '../api/http'
export default {
  name: 'Profile',
  components: {
    VueQr,
  },
  data () {
    return {
      user: {},
      // echarts 数据
      dataArr: [
        { value: 260, name: '基础测试能力' },
        { value: 260, name: '测试编程能力' },
        { value: 280, name: '客户端测试能力' },
        { value: 160, name: '服务端测试能力' },
        { value: 260, name: '持续集成/交付能力' },
        { value: 260, name: '测试左移右移' }
      ],
      text: ''
    }
  },
  methods: {
    //绘制echarts图
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      myChart.on('finished', () => {
        setTimeout(this.saveHtml2Image, 1000);
      })
      console.log(this.dataArr)
      let data = this.dataArr
      // 绘制图表
      myChart.setOption({
        backgroundColor: '#f3f9f1',
        title: {
          text: '个人能力分析',
          left: 'center',
          top: 20,
          textStyle: {
            color: '#000000'
          }
        },
        // tooltip: { //鼠标悬浮提示
        //   trigger: 'item'
        // },
        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1]
          }
        },
        series: [{
          name: '访问来源',
          type: 'pie',
          radius: '55%',
          center: ['48%', '50%'],
          data: data,
          roseType: 'radius',
          label: {
            color: 'rgba(0, 0, 0, 1)',
            normal: {
              formatter (v) {
                let text = v.name
                return text.length < 4 ? text : `${text.slice(0, 4)}\n${text.slice(4)}`
              }
            }
          },
          labelLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 1)'
            },
            smooth: 0.3,
            length: 13,
            length2: 13
          },
          itemStyle: {
            color: '#c23531',
            // shadowBlur: 200, 盒阴影
            shadowBlur: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          },

          animationType: 'scale',
          animationEasing: 'elasticOut',
          animationDelay: function () {
            return Math.random() * 200;
          }
        }]
      });
    },

    goHome () {
      this.$router.push('/')
    },

    //获取用户个人能力数据 
    async getProfileResult () {
      let openid = JSON.parse(localStorage.getItem('user')).openid
      let res = await axios({
        method: "GET",
        url: "/evaluation/getResult",
        params: { openid },
      })
      console.log(res)
      if (res.data) {
        localStorage.setItem("search", true);
      }
      this.dataArr[0].value = res.data.score_base
      this.dataArr[1].value = res.data.score_code
      this.dataArr[2].value = res.data.score_client
      this.dataArr[3].value = res.data.score_server
      this.dataArr[4].value = res.data.score_ci_cd
      this.dataArr[5].value = res.data.score_shift
      this.drawLine()
    },

    //从本地获取用户信息
    getProfileInfo () {
      this.user = JSON.parse(localStorage.getItem('user'))
      console.log(this.user)
    },

    saveHtml2Image () {
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      if (document.getElementById('profile-poster-Img')) {
        document.getElementById('profile-poster-Img').style.display = "block"
        return
      }
      html2canvas(document.querySelector('#posterHtml'), {
        useCORS: true,
        allowTaint: true,
      }).then(async canvas => {
        const img = document.createElement("img");
        img.src = canvas.toDataURL("image/png")
        document.body.appendChild(img);
        img.style.cssText =
          "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:99;";
        img.id = "profile-poster-Img"
      });
    },

  },

  created () {
    this.getProfileInfo();
  },
  mounted () {
    this.getProfileResult()
  },
}
</script>

<style  scoped>
#posterHtml {
  width: 100%;
  height: 18.4rem;
  /* height: 100%; */
  /* background: url("../assets/bg.jpg"); */
  background-repeat: no-repeat;
  background-size: 100% auto;
  -webkit-background-size: 100% auto;
  /* background-attachment: fixed; */
  /* -webkit-background-attachment: fixed; */
}
#bg-img {
  width: 100%;
  /* height: 100%; */
}
.profile {
  position: absolute;
  top: 0.427rem;
  left: 0.417rem;
  z-index: 2;
  display: flex;
}
.user-avatar {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  vertical-align: middle;
}
.profile-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0.053rem;
  margin-left: 0.133rem;
}
.user-name {
  font-size: 14px;
  font-family: "KaiTi";
  color: #fff;
}
.user-tip {
  font-size: 12px;
  font-family: "KaiTi";
  color: #fff;
}
.qrcode {
  width: 2.167rem;
  height: 2.167rem;
  position: absolute;
  /* right: 0.917rem;
  bottom: 0.167rem; */
  right: 0.853rem;
  bottom: 0.72rem;
  z-index: 2;
}
.qrcode .qrcode-img {
  width: 2.167rem;
  height: 2.167rem;
}

.loading-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  text-align: center;
}

/* echarts */
.chart-container {
  width: 100%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
  position: absolute;
  top: 7.52rem;
}
#myChart {
  width: 8.8rem;
  height: 7.6rem;
  /* background-color: white; */
}

/* 底部提示文字 */
.tips {
  width: 100%;
  height: 1.6rem;
  background-color: #ff7f50;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.tips span {
  font-size: 20px;
  font-weight: 700;
  font-family: "KaiTi";
}
.tips .triangle {
  width: 0;
  height: 0;
  border-width: 0 20px 20px;
  border-style: solid;
  border-color: transparent transparent #ff7f50;
  position: absolute;
  top: -12px;
  left: 30px;
}

/* 菜单 */
.menu {
  width: 100%;
  height: 1.6rem;
  background-color: #ffffff;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px red, /*左边阴影*/ 0px 0px 3px gray,
    /*上边阴影*/ 0px 0px 0px green, /*右边阴影*/ 0px 0px 0px blue; /*下边阴影*/
}
.menu div {
  width: 2.667rem;
  height: 35px;
  line-height: 35px;
  font-size: 0.533rem;
  text-align: center;
  color: #e05331;
}
</style>